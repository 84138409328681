import React from 'react';
import { Router } from '@reach/router';

import EventbriteEventDetail from '../containers/event-calendar/brite-event-detail';

export default function EventbriteEventPage() {
  return (
    <Router>
      <EventbriteEventDetail path="/eventbrite/:eventbriteEventId" />
    </Router>
  );
}
